import { Box, Button, Container, Divider, Link, Typography } from '@mui/material'
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useOutletContext } from 'react-router-dom'
import * as yup from 'yup'
import { TextField, TextPassword, SlideContainer, SlideBox } from 'src/components'

import { Alert } from 'src/components/show/Alert'
import { useApi, useStateManage, reinsertFormValues } from 'src/util/'
import { useMenuRout } from 'src/Rout'

const LOGIN = yup.object({
  user_id: yup.string().required('User ID は必須項目です。'),
  password: yup.string().required('Password は必須項目です。'),
  new_password: yup.string().when('step', {
    is: 2,
    then: () =>
      yup
        .string()
        .required('新しいパスワードは必須項目です。')
        .matches(
          /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*[0-9])(?=.*[#$%&@*+-_])[a-zA-Z0-9#$%&@*+-_]{8,32}$/,
          'パスワードは8文字以上で半角英数字と記号を含めてください。'
        )
        .notOneOf([yup.ref('password')], '現在のパスワードと同じパスワードは使用できません。')
  }),
  confirm: yup.string().when('step', {
    is: 2,
    then: () =>
      yup
        .string()
        .required('新しいパスワードの確認は必須項目です。')
        .oneOf([yup.ref('new_password')], '新しいパスワードと一致しません。')
  }),
  authcode: yup.string().when('step', {
    is: 3,
    then: () => yup.string().required('Authcode は必須項目です。')
  })
})

const Login = () => {
  console.log('Login')
  const request = useApi({ cleareMessage: true })
  const navigate = useNavigate()
  const [global, render] = useOutletContext()
  const { state, setObject } = useStateManage({
    loginNg: false,
    message: '',
    isTwoFactor: false,
    back: false,
    activeStep: 1
  })
  const initializeMenu = useMenuRout()

  const form = useForm({
    // mode: 'onBlur',
    defaultValues: { step: 1, user_id: '', password: '', new_password: '', confirm: '', authcode: '' },
    resolver: yupResolver(LOGIN)
  })

  const onSubmit = (values) => {
    let sendDate = values
    if (state.activeStep === 1) {
      sendDate = { user_id: values.user_id, password: values.password }
    } else if (state.activeStep === 2) {
      sendDate = { user_id: values.user_id, password: values.password, new_password: values.new_password }
    } else if (state.activeStep === 3) {
      sendDate = { user_id: values.user_id, password: values.password, authcode: values.authcode }
    }

    request.post(
      '/login',
      sendDate,
      ({ user, body }) => {
        if (user.loginStatus === 1) {
          initializeMenu(body.menu, body.allowedMenuPaths, body.lastLocation)
          navigate(body.lastLocation, { replace: true })
        }
      },
      ({ body }) => {
        reinsertFormValues(form)
        if (body.errCode === '001') {
          // form.setFocus('new_password')
          setObject({ loginNg: false })
          setObject({ back: false, activeStep: 2 })
        } else if (body.errCode === '002') {
          // form.setFocus('authcode')
          setObject({ loginNg: false })
          setObject({ back: false, activeStep: 3 })
        } else {
          setObject({ loginNg: true, message: body.message })
        }
      }
    )
  }

  useEffect(() => {
    const step = state.activeStep
    form.setValue('step', step)
    if (step === 2) {
      setTimeout(() => form.setFocus('new_password'), 300)
    } else if (step === 3) {
      setTimeout(() => form.setFocus('authcode'), 300)
    }
  }, [state.activeStep])

  const handleBack = () => {
    form.setValue('step', 1)
    form.setValue('authcode', '')
    // form.resetField('authcode')
    setObject({ back: true, activeStep: 1 })
  }

  return (
    <>
      <Helmet>
        <title>Login | M System</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          pb: 5
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Box sx={{ pb: 1 }}>
              <Divider variant="middle">
                <Typography align="center" variant="body1">
                  管理ユーザ向け システムログイン
                </Typography>
              </Divider>
            </Box>
            <Box sx={{ pb: 2 }}>
              {global.messages &&
                global.messages.map((message, i = 0) => (
                  <Alert
                    key={i}
                    open={message.open}
                    onClose={() => render('messages', i, 'open', false)}
                    severity={message.severity}
                  >
                    {message.body}
                  </Alert>
                ))}
              <Alert
                open={state.loginNg}
                onClose={() => {
                  setObject({ loginNg: false })
                }}
                severity="error"
                sx={{ mb: 0 }}
              >
                {state.message}
              </Alert>
            </Box>

            <SlideContainer state={state}>
              <SlideBox state={state} no={1}>
                <TextField
                  form={form}
                  name="user_id"
                  label="User ID"
                  margin="normal"
                  size="middle"
                  autoComplete="on"
                  autoFocus
                />
                <TextPassword form={form} name="password" label="Password" margin="normal" size="middle" />
              </SlideBox>
              <SlideBox state={state} no={2}>
                <Typography color="textSecondary" fontSize="0.875rem" mt={2}>
                  初期パスワードを変更してください。
                </Typography>
                <TextPassword form={form} name="new_password" label="新しいパスワード*" margin="normal" size="middle" />
                <TextPassword
                  form={form}
                  name="confirm"
                  label="新しいパスワードの確認*"
                  margin="normal"
                  size="middle"
                />
              </SlideBox>
              <SlideBox state={state} no={3}>
                <Typography color="textSecondary" fontSize="0.875rem" mt={2}>
                  アカウントは二要素認証で保護されています。認証コードを入力してください。
                </Typography>
                <TextField
                  form={form}
                  name="authcode"
                  label="Authcode"
                  margin="normal"
                  size="middle"
                  inputProps={{ maxLength: 6 }}
                  sx={{ mt: 3 }}
                />
              </SlideBox>
            </SlideContainer>

            <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
              {state.activeStep === 1 && (
                <>
                  <div />
                  <Button color="primary" type="submit" variant="contained">
                    ログイン
                  </Button>
                </>
              )}
              {state.activeStep === 2 && (
                <>
                  <div />
                  <Button color="primary" type="submit" variant="contained">
                    パスワード変更
                  </Button>
                </>
              )}
              {state.activeStep === 3 && (
                <>
                  <Button type="button" startIcon={<ChevronLeftIcon />} onClick={handleBack}>
                    前へ
                  </Button>
                  <Button color="primary" type="submit" variant="contained">
                    ログイン
                  </Button>
                </>
              )}
            </Box>
            <Typography color="textSecondary" variant="body1" fontSize="0.875rem">
              お問い合わせは
              <Link href="https://www.secomtrust.net/sts/contact/entry.html" variant="h6" underline="hover">
                こちら
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>
    </>
  )
}

export default Login

import { Button, DialogActions, DialogContent, MenuItem, Grid } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import * as yup from 'yup'
import { TextField, Dialog, DialogText } from 'src/components'
import { useApi, isEqualDiff, setFormInit } from 'src/util/'

const USER_EDIT = yup.object({
  user_name: yup.string().trim().required('名前は必須項目です。'),
  mail: yup.string().nullable().email('有効なメールアドレス形式でありません。'),
  auth_group_id: yup.string().required('権限グループは必須項目です。')
})

const UserTableMore = ({ dialog, setDialog, authGroupList }) => {
  console.log('UserTableMore')
  const request = useApi()

  const form = useForm({
    defaultValues: { user_id: '', user_name: '', mail: '', auth_group_id: '', is_locked: '' },
    resolver: yupResolver(USER_EDIT)
  })

  const onSubmit = (values) => {
    if (isEqualDiff(values, dialog.selectedUser)) return
    request.patch('/user', values, () => {
      document.getElementById('pageReflesh').click()
    })
  }

  useEffect(() => {
    if (!dialog.open) return
    setFormInit(form, dialog.selectedUser)
    form.trigger('_render')
  }, [dialog.open])

  return (
    <>
      <Dialog title="アカウント情報の変更" open={dialog.open} close={() => setDialog({ open: false })} maxWidth="lg">
        <DialogContent sx={{ overflow: 'hidden', p: 2 }}>
          <DialogText sx={{ mb: 2 }}>{`「${form.getValues('user_id')}」の変更内容を入力してください。`}</DialogText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField form={form} name="user_name" label="名前*" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField form={form} name="mail" label="メール" inputProps={{ maxLength: 64 }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                form={form}
                name="auth_group_id"
                label="権限グループ*"
                select
                InputLabelProps={{ shrink: true }}
              >
                {authGroupList &&
                  authGroupList.map((item) => (
                    <MenuItem key={item.auth_group_id} value={item.auth_group_id}>
                      {item.auth_group_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField form={form} name="is_locked" label="ロック" select InputLabelProps={{ shrink: true }}>
                <MenuItem key={1} value="1">
                  有
                </MenuItem>
                <MenuItem key={2} value="0">
                  無
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog({ open: false })}>キャンセル</Button>
          <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
            送信
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

UserTableMore.propTypes = {
  dialog: PropTypes.object,
  setDialog: PropTypes.func,
  authGroupList: PropTypes.array
}

export default UserTableMore

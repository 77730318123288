import { Card, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect } from 'react'
import { StyledCell, HeaderBox, FooterBox, Dialog, Pagination, SortCell, Loading } from 'src/components'
import { useApi, useStateManage, usePageControl } from 'src/util/'
import CertTableBody from './CertTableBody'

const CertTable = () => {
  console.log('CertTable')
  const request = useApi()
  const pc = usePageControl()
  const { state, setObject } = useStateManage({
    dataTotal: 0,
    pageTotal: 0,
    pageNo: 0,
    ejbca_id: undefined,
    list: [],
    retryDialog: false
  })

  const search = (pageNo, values) => {
    request.setOption({ loading: 'load1' })
    request.get(`/ejbcas/certList/${pageNo}`, values, ({ body }) => {
      setObject({
        dataTotal: body.page.dataTotal,
        pageTotal: body.page.pageTotal,
        pageNo,
        ejbca_id: values.ejbca_id,
        list: [...body.rows]
      })
    })
  }
  useEffect(() => pc.initSearch(search, () => setObject({ retryDialog: true })), [])

  const _handleSort = (_sort) => {
    if (state.dataTotal === 0) return
    pc.handleSort(_sort, () => setObject({ retryDialog: true }))
  }

  return (
    <Card>
      <Loading name="load1" sx={{ top: '10px' }} />
      <HeaderBox title="証明書一覧">{state.dataTotal !== 0 && '計' + state.dataTotal + '件'}</HeaderBox>
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <SortCell sx={{ minWidth: 120 }} name="ca_name" sort={pc.sort} onClick={_handleSort}>
                CA名
              </SortCell>
              <SortCell sx={{ minWidth: 260 }} name="issuer_dn" sort={pc.sort} onClick={_handleSort}>
                発行者DN
              </SortCell>
              <SortCell sx={{ minWidth: 260 }} name="subject_dn" sort={pc.sort} onClick={_handleSort}>
                主体者DN
              </SortCell>
              <SortCell sx={{ minWidth: 90 }} name="expire_date" sort={pc.sort} onClick={_handleSort}>
                有効期限
              </SortCell>
              <SortCell sx={{ minWidth: 95 }} name="status" sort={pc.sort} onClick={_handleSort}>
                ステータス
              </SortCell>
              <SortCell sx={{ minWidth: 90 }} name="update_time" sort={pc.sort} onClick={_handleSort}>
                更新日時
              </SortCell>
              <StyledCell sx={{ width: 24 }} />
            </TableRow>
          </TableHead>
          <CertTableBody ejbca_id={state.ejbca_id} list={state.list} />
        </Table>
      </TableContainer>
      <FooterBox sx={{ justifyContent: 'center' }}>
        <Pagination
          state={state}
          setFormRequest={pc.setFormRequest}
          closeRetryDialog={() => setObject({ retryDialog: false })}
        />
      </FooterBox>
    </Card>
  )
}

export default CertTable

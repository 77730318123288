import { Card, Table, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect } from 'react'
import { StyledCell, HeaderBox, FooterBox, Pagination, SortCell, Loading } from 'src/components'
import { useApi, useStateManage, usePageControl } from 'src/util/'
import AcmeTableBody from './SciWsTableBody'

const SciWsTable = () => {
  console.log('SciWsTable')
  const request = useApi()
  const pc = usePageControl()
  const { state, setObject } = useStateManage({ dataTotal: 0, pageTotal: 0, pageNo: 0, list: [], retryDialog: false })

  const search = (pageNo, values) => {
    request.setOption({ loading: 'load1' })
    request.get(`/sciws/requestHistory/${pageNo}`, values, ({ body }) => {
      setObject({
        dataTotal: body.page.dataTotal,
        pageTotal: body.page.pageTotal,
        pageNo,
        list: [...body.rows]
      })
    })
  }
  useEffect(() => pc.initSearch(search, () => setObject({ retryDialog: true })), [])

  const _handleSort = (_sort) => {
    if (state.dataTotal === 0) return
    pc.handleSort(_sort, () => setObject({ retryDialog: true }))
  }

  return (
    <Card>
      <Loading name="load1" sx={{ top: '10px' }} />
      <HeaderBox title="SCI-WS 履歴一覧">{state.dataTotal !== 0 && '計' + state.dataTotal + '件'}</HeaderBox>
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <SortCell sx={{ minWidth: 90 }} name="request_id" sort={pc.sort} onClick={_handleSort}>
                No
              </SortCell>
              <SortCell sx={{ minWidth: 140 }} name="sciws_name" sort={pc.sort} onClick={_handleSort}>
                SCI-WS名
              </SortCell>
              <SortCell sx={{ minWidth: 140 }} name="method" sort={pc.sort} onClick={_handleSort}>
                メソッド名
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="args_service_info_id" sort={pc.sort} onClick={_handleSort}>
                契約ID
              </SortCell>
              <SortCell sx={{ minWidth: 180 }} name="respons_status_cd" sort={pc.sort} onClick={_handleSort}>
                ステータスCD
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="respons_at" sort={pc.sort} onClick={_handleSort}>
                レスポンス日時
              </SortCell>
              <StyledCell sx={{ width: 24 }} />
            </TableRow>
          </TableHead>
          <AcmeTableBody list={state.list} />
        </Table>
      </TableContainer>
      <FooterBox sx={{ justifyContent: 'center' }}>
        <Pagination
          state={state}
          setFormRequest={pc.setFormRequest}
          closeRetryDialog={() => setObject({ retryDialog: false })}
        />
      </FooterBox>
    </Card>
  )
}

export default SciWsTable

import { Button, Card, Checkbox, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, StyledCell, HeaderBox, FooterBox } from 'src/components'
import { useApi, diffForm, createDiffParams } from 'src/util/'
import { useMenuRout } from 'src/Rout'

const AppMenuUsable = ({ selectedGroup }) => {
  const initializeMenu = useMenuRout()
  const request = useApi()
  const [response, setResponse] = useState({})
  const [open, setDialog] = useState(false)
  const [form, setForm] = useState([])

  const initTable = () => {
    if (selectedGroup.auth_group_id) {
      request.get('/appMenu/showable', { auth_group_id: selectedGroup.auth_group_id }, ({ body }) => {
        setForm([...body])
        setResponse(body)
      })
    }
  }
  useEffect(initTable, [selectedGroup.auth_group_id])

  const handleDiffForm = (id, name, value) => setForm(diffForm(id, name, value, response, form))
  const handleDiffCheckbox = (e) => handleDiffForm(e.target.id, e.target.name, e.target.checked)

  const submitUpdate = (e) => {
    const reqestParams = createDiffParams(e, form, ['auth_group_id', 'app_menu_id'], ['showable'])
    if (!reqestParams.length) return
    request.put(
      '/appMenu/bulk',
      reqestParams,
      () => {
        setDialog(true)
        request.get('/myInfo', null, ({ body }) => {
          initializeMenu(body.menu, body.allowedMenuPaths)
          initTable()
        })
      },
      () => initTable()
    )
  }

  return (
    <Card>
      <HeaderBox title="アプリメニュー権限">{/* <MoreIconButton itemList={itemList} /> */}</HeaderBox>
      <TableContainer>
        {/* <PerfectScrollbar> */}
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <StyledCell>ID</StyledCell>
              <StyledCell>親ID</StyledCell>
              <StyledCell sx={{ minWidth: 100 }}>メニュータイトル</StyledCell>
              <StyledCell sx={{ minWidth: 100 }}>パス</StyledCell>
              <StyledCell align="center">使用可</StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {form &&
              form.map((row, index) => (
                <TableRow hover key={row.app_menu_id}>
                  <StyledCell>{row.app_menu_id}</StyledCell>
                  <StyledCell>{row.parent_id}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.menu_title}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.path}</StyledCell>
                  <StyledCell align="center" turnOnBgColor={row.showable_diff} sx={{ p: '0' }}>
                    <Checkbox
                      id={index + ''}
                      name="showable"
                      checked={row.showable}
                      value={row.showable}
                      disabled={row.not_changeable}
                      color="secondary"
                      onChange={handleDiffCheckbox}
                    />
                  </StyledCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {/* </PerfectScrollbar> */}
      </TableContainer>
      {form.length !== 0 && (
        <FooterBox>
          <Button type="submit" onClick={submitUpdate} variant="contained">
            更新
          </Button>
          <Dialog title="アプリメニュー権限の更新" open={open} close={() => setDialog(false)}>
            アプリメニュー権限が更新されました。
          </Dialog>
        </FooterBox>
      )}
    </Card>
  )
}

AppMenuUsable.propTypes = {
  selectedGroup: PropTypes.object
}

export default AppMenuUsable

import { Card, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect } from 'react'
import { StyledCell, HeaderBox, FooterBox, Pagination, SortCell } from 'src/components'
import { useApi, useStateManage, usePageControl } from 'src/util/'

const HistoryTable = () => {
  console.log('HistoryTable')
  const request = useApi()
  const pc = usePageControl()
  const { state, setObject } = useStateManage({ dataTotal: 0, pageTotal: 0, pageNo: 0, list: [], retryDialog: false })

  const search = (pageNo, values) => {
    request.get(`/ejbcas/logHistory/${pageNo}`, values, ({ body }) => {
      setObject({
        dataTotal: body.page.dataTotal,
        pageTotal: body.page.pageTotal,
        pageNo,
        list: [...body.rows]
      })
    })
  }
  useEffect(() => pc.initSearch(search, () => setObject({ retryDialog: true })), [])

  const _handleSort = (_sort) => {
    if (state.dataTotal === 0) return
    pc.handleSort(_sort, () => setObject({ retryDialog: true }))
  }

  return (
    <Card>
      <HeaderBox title="EJBCAログ履歴">{state.dataTotal !== 0 && '計' + state.dataTotal + '件'}</HeaderBox>
      <TableContainer>
        <Table sx={{ borderCollapse: 'separate' }} size="small">
          <TableHead sx={{ wordBreak: 'keep-all' }}>
            <TableRow>
              <SortCell sx={{ minWidth: 140 }} name="remote_name" sort={pc.sort} onClick={_handleSort}>
                リモート名
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="host_name" sort={pc.sort} onClick={_handleSort}>
                ホスト名
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="ca_name" sort={pc.sort} onClick={_handleSort}>
                CA名
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="timeStamp" sort={pc.sort} onClick={_handleSort}>
                タイムスタンプ
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="eventStatus" sort={pc.sort} onClick={_handleSort}>
                イベントステータス
              </SortCell>
              <SortCell sx={{ minWidth: 120 }} name="eventType" sort={pc.sort} onClick={_handleSort}>
                イベントタイプ
              </SortCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.dataTotal !== 0 &&
              state.list.map((row, index) => (
                <TableRow hover key={`${row.remote_name}${row.host_name}${row.ca_name}${row.timeStamp}`}>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.remote_name}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.host_name}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.ca_name}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'keep-all' }}>{row.timeStamp_d}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.eventStatus}</StyledCell>
                  <StyledCell sx={{ wordBreak: 'break-all' }}>{row.eventType}</StyledCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FooterBox sx={{ justifyContent: 'center' }}>
        <Pagination
          state={state}
          setFormRequest={pc.setFormRequest}
          closeRetryDialog={() => setObject({ retryDialog: false })}
        />
      </FooterBox>
    </Card>
  )
}

export default HistoryTable

import { Box, Button, Card, CardContent, Divider, Grid, IconButton } from '@mui/material'
import { KeyboardReturn as KeyboardReturnIcon } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { useEffect, useState, useRef } from 'react'
import { Link as RouterLink, useOutletContext } from 'react-router-dom'
import { HeaderBox, TextField } from 'src/components'
import { useApi, createMessage, usePageControl } from 'src/util/'
import CertIssue from './CertIssue'

export const CertSearch = () => {
  console.log('CertSearch')
  const [global, render] = useOutletContext()
  const request = useApi()
  const pc = usePageControl()
  const [dialog, setDialog] = useState({ open: false, message: '' })

  const form = useForm({
    defaultValues: {
      ejbca_id: '',
      title: '',
      ca_name: '',
      issuer_dn: '',
      subject_dn: '',
      expire_date: '',
      status: '',
      update_time: ''
    }
  })

  const onSubmit = (values) => {
    pc.unusedRequestValue('title')
    // certList検索
    pc.setFormRequest(form, { btn: 'search', pageNo: 1, values })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(decodeURI(window.location.search))
    const values = {
      ejbca_id: searchParams.get('ejbca_id'),
      ca_id: searchParams.get('ca_id')
    }
    if (!values.ejbca_id) {
      global.messages.push(createMessage('error', 'ejbca_idを指定してください。'))
      render()
      return
    }

    request.get('/ejbcas/ca/1', values, ({ body }) => {
      const row = body.rows[0]
      if (!row) return

      form.setValue('ejbca_id', row.ejbca_id)
      form.setValue('title', `${row.remote_name} / ${row.host_name}`)
      if (values.ca_id) form.setValue('ca_name', row.ca_name)
      form.handleSubmit(onSubmit)()
    })
  }, [])

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Card sx={{ height: '100%' }}>
        <HeaderBox title={`証明書 検索（${form.getValues('title')}）`}>
          <IconButton sx={{ my: '-8px', transform: 'scale(1, -1)' }} component={RouterLink} to="/app/ejbcaData">
            <KeyboardReturnIcon />
          </IconButton>
        </HeaderBox>
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2}>
              <TextField form={form} name="ca_name" label="CA名" inputProps={{ maxLength: 64 }} />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField form={form} name="issuer_dn" label="発行者DN" inputProps={{ maxLength: 250 }} />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField form={form} name="subject_dn" label="主体者DN" inputProps={{ maxLength: 250 }} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                form={form}
                name="expire_date"
                label="有効期限（以降）"
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField form={form} name="status" label="ステータス" inputProps={{ maxLength: 2 }} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                form={form}
                name="update_time"
                label="更新日時（以降）"
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1, px: 2 }}>
          <CertIssue dialog={dialog} setDialog={setDialog} searchVals={form.getValues()} />
          <Button type="button" color="primary" variant="contained" onClick={() => setDialog({ open: true })}>
            直接発行
          </Button>
          <Button type="submit" color="primary" variant="contained">
            検索
          </Button>
        </Box>
      </Card>
    </form>
  )
}

export default CertSearch

import React, { useState, useEffect, useRef } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import DashboardLayout from './contents/_framework/DashboardLayout'
// import Test from './contents/_framework/DashboardSidebar'
import MainLayout from './contents/_framework/MainLayout'
import Login from './main/Login'
import NotFound from './main/NotFound'
import { createUser, useApi } from './util/api'
import PageReflesh from './main/PageReflesh'
import PublicInfo from './main/PublicInfo'
import MyAccount from './main/MyAccount'
import UrlMonitor from './main/UrlMonitor'
import UrlMonitorSetting from './main/UrlMonitorSetting'
import AuthManage from './main/AuthManage'
import UserManage from './main/UserManage'
import RemotoLoginHistory from './main/RemotoLoginHistory'
import RemotoConect from './main/RemotoConect'
import BatchManage from './main/BatchManage'
import EjbcaData from './main/EjbcaData'
import CertList from './main/CertList'
import EjbcaLogHistory from './main/EjbcaLogHistory'
import AcmeAccount from './main/AcmeAccount'
import AcmeOrder from './main/AcmeOrder'
import SciWs from './main/SciWs'

const App = () => {
  const [global, setGlobal] = useState({
    user: createUser(),
    messages: [],
    dispMenus: [],
    allowedMenuPaths: {}
  })
  const _prev = useRef({})
  const _gRef = useRef(JSON.parse(JSON.stringify(global)))

  /**
   * アプリのグローバルなステート値を更新する
   * 差分が存在しない場合は、描画しない。
   */
  const renderGlobalState = (var1, var2, var3, var4) => {
    const args = [var1, var2, var3, var4].filter((elem) => elem !== undefined)
    if (args.length === 2) {
      _gRef.current[var1] = var2
    } else if (args.length === 3) {
      _gRef.current[var1][var2] = var3
    } else if (args.length === 4) {
      _gRef.current[var1][var2][var3] = var4
    }

    const currentJson = JSON.stringify(_gRef.current)
    const prevJson = JSON.stringify(_prev.current)
    if (prevJson === currentJson) return

    _prev.current = JSON.parse(currentJson)
    setGlobal(_prev.current)
  }

  console.log('----- App: ', global)

  return (
    <Routes>
      <Route path="/" element={<MainLayout global={_gRef.current} render={renderGlobalState} />}>
        <Route path="" element={<Navigate to="/login" />} />
        <Route path="*" element={<NotFound />} />
        <Route path="login" element={<Login />} />
        {/* <Route path="test" element={<Test />} /> */}
        {/* <Route path="404" element={<NotFound />} /> */}
      </Route>
      <Route path="app" element={<DashboardLayout global={_gRef.current} render={renderGlobalState} />}>
        <Route path="" element={<Navigate to="/app/publicInfo" />} />
        {/* <Route path="*" element={<Navigate to="/404" />} /> */}
        <Route path="pageReflesh" element={<PageReflesh />} />
        {/* <Route path="pageReflesh" element={<Navigate to={-1} />} /> */}
        <Route path="publicInfo" element={<PublicInfo />} />
        {/* {global.dispMenus.map((item) => APP_ROUTES[item.rout])} */}
        <Route key={100} path="publicInfo" element={<PublicInfo />} />
        <Route key={200} path="urlMonitor" element={<UrlMonitor />} />
        <Route key={201} path="urlMoniSetting" element={<UrlMonitorSetting />} />
        <Route key={300} path="myAccount" element={<MyAccount />} />
        <Route key={301} path="authManage" element={<AuthManage />} />
        <Route key={302} path="userManage" element={<UserManage />} />
        <Route key={400} path="remotoConect" element={<RemotoConect />} />
        <Route key={401} path="remotoLoginHistory" element={<RemotoLoginHistory />} />
        <Route key={402} path="batchManage" element={<BatchManage />} />
        <Route key={403} path="batchManage/history" element={<BatchManage />} />
        <Route key={500} path="ejbcaData" element={<EjbcaData />} />
        <Route key={501} path="ejbcaData/certList" element={<CertList />} />
        <Route key={502} path="ejbcaLogHistory" element={<EjbcaLogHistory />} />
        <Route key={600} path="acmeAccount" element={<AcmeAccount />} />
        <Route key={601} path="acmeOrder" element={<AcmeOrder />} />
        <Route key={700} path="sciRaWs" element={<SciWs />} />
      </Route>
    </Routes>
  )
}

export default App
